import { defineComponent } from '@vue/composition-api';

/**
 * This isn't correctly typed as a mixin, so return types etc. are documented within `shims-tsx.d.ts`
 */
export default defineComponent({
  name: 'GlobalMixin',
  methods: {
    isSameResource(snakeCase: string, camelCase: string): boolean {
      const toSnakeCase = (str: string) =>
        str &&
        str
          .match(
            /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
          )
          ?.map((x) => x.toUpperCase())
          .join('_');

      return snakeCase === toSnakeCase(camelCase);
    },
  },
});
