import flatten from 'flat';
import resultScaleMapping from '@/data/resultScaleMapping.json';
import { LS_ACCESS_TOKEN, LS_REFRESH_TOKEN } from './constants';

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export function getAccessToken() {
  if (typeof localStorage === 'undefined') {
    return null;
  }

  return localStorage.getItem(LS_ACCESS_TOKEN);
}

export function getRefreshToken() {
  if (typeof localStorage === 'undefined') {
    return null;
  }

  return localStorage.getItem(LS_REFRESH_TOKEN);
}

export function setAccessToken(token) {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(LS_ACCESS_TOKEN, token);
  }
}

export function setRefreshToken(refreshToken) {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(LS_REFRESH_TOKEN, refreshToken);
  }
}

export function setExpiresIn(timeStamp) {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('token-expiration', timeStamp);
  }
}

export function isTokenExpired() {
  const token = getAccessToken();
  if (!token) return true;

  const expiration = parseInt(parseJwt(token).exp);
  const now = Math.floor(new Date().getTime() / 1000);
  // console.log(expiration - now, 'seconds');
  if (now <= expiration) {
    return false;
  }
  return true;
}

/**
 * @returns {'ADMIN' | 'SCHOOL_DISTRICT_MANAGER' | 'PARTNER_MANAGER' | 'MANAGER' | 'COACH' | (string & {})}
 */
export function getRole() {
  const token = getAccessToken();
  if (!token) return null;
  return parseJwt(token).role;
}

export function getResourceType() {
  const token = getAccessToken();
  if (!token) return null;
  const parsedToken = parseJwt(token);
  if (parsedToken.resource && parsedToken.resource.resource)
    return parsedToken.resource.resource;
  return null;
}

export function getResource() {
  const token = getAccessToken();
  if (!token) return null;
  const parsedToken = parseJwt(token);
  if (parsedToken.resource && parsedToken.resource.resourceId)
    return parsedToken.resource.resourceId;
  return null;
}

export function getSchool() {
  const token = getAccessToken();
  if (!token) return null;
  const parsedToken = parseJwt(token);
  if (parsedToken.resource && parsedToken.resource.resource === 'SCHOOL')
    return parsedToken.resource.resourceId;
  return null;
}

export function getId() {
  const token = getAccessToken();
  if (!token) return null;
  return parseJwt(token).sub;
}

export function handleGraphQLErrors(e) {
  console.log(e.graphQLErrors);
  return e.graphQLErrors
    .map((graphQLError) => {
      if (
        typeof graphQLError.extensions.exception.response.message === 'string'
      )
        return graphQLError.extensions.exception.response.message;
      return graphQLError.extensions.exception.response.message.join(', ');
    })
    .join(', ');
}

// flatten the data and remove graphql created keys with __typename
export function stripAndFlatten(data) {
  if (!data || !data.length) return null;
  const keysToDelete = Object.keys(flatten(data[0])).filter((key) =>
    key.includes('__typename'),
  );
  return data.map(flatten).map((item) => {
    keysToDelete.forEach((keyToDelete) => {
      // eslint-disable-next-line no-param-reassign
      delete item[keyToDelete];
    });

    // Object.keys(item).forEach((key) => {
    //   if (item[key] === null) {
    //     item[key] = '-';
    //   }
    // });

    return item;
  });
}

export const findAndReplaceObject = (input, needle, replacement) =>
  Object.fromEntries(
    Object.entries(input).map(([key, entry]) => {
      if (entry === needle) {
        return [key, replacement];
      }
      if (Object.prototype.toString.call(entry) === '[object Object]') {
        return [key, findAndReplaceObject(entry, needle, replacement)];
      }
      return [key, entry];
    }),
  );

export function defaultFiller(data, fillers) {
  return data.map((item) => {
    Object.keys(item).forEach((key) => {
      if (item[key] === null && fillers[key]) {
        // eslint-disable-next-line no-param-reassign
        item[key] = fillers[key];
      }
    });
    return item;
  });
}

export function calculateScaleMapping(value, type) {
  if (!resultScaleMapping[type] || value === null) return 'none';
  return (
    5 - resultScaleMapping[type].filter((result) => value >= result).length
  );
}

export function scaleMappingExists(type) {
  return resultScaleMapping[type];
}
