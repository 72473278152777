export function formatDate(date, showTime = false) {
  if (!date) return null;
  const formattedDate = new Date(date);
  let result = '';
  const day = formattedDate.getDate();
  const month = formattedDate.getMonth() + 1;
  const year = formattedDate.getFullYear();
  const hours = formattedDate.getHours();
  const minutes = formattedDate.getMinutes();

  // let result = `${day}-${month}-${year} `;

  if (day <= 9) {
    result = `${result}0${day}-`;
  } else {
    result = `${result}${day}-`;
  }
  if (month <= 9) {
    result = `${result}0${month}-`;
  } else {
    result = `${result}${month}-`;
  }
  result = `${result}${year}`;

  if (showTime) {
    if (hours <= 9) {
      result = `${result} 0${hours}`;
    } else {
      result = `${result} ${hours}`;
    }
    if (minutes <= 9) {
      result = `${result}:0${minutes}`;
    } else {
      result = `${result}:${minutes}`;
    }
  }

  return result;
}

export function reverseDate(date) {
  if (!date) return null;
  const formattedDate = new Date(date);
  let result = '';
  const day = formattedDate.getDate();
  const month = formattedDate.getMonth() + 1;
  const year = formattedDate.getFullYear();

  result = `${result}${year}-`;
  if (month <= 9) {
    result = `${result}0${month}-`;
  } else {
    result = `${result}${month}-`;
  }
  if (day <= 9) {
    result = `${result}0${day}`;
  } else {
    result = `${result}${day}`;
  }

  return result;
}

export function getDay(date) {
  let result = '';
  const weekdays = ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo'];

  const formattedDate = new Date(date);
  result = `${weekdays[formattedDate.getDay()]} `;
  const day = formattedDate.getDate();
  const month = formattedDate.getMonth() + 1;

  if (day <= 9) {
    result = `${result}0${day}-`;
  } else {
    result = `${result}${day}-`;
  }
  if (month <= 9) {
    result = `${result}0${month}`;
  } else {
    result = `${result}${month}`;
  }

  return result;
}

export function daysAgo(date) {
  const today = new Date();
  const createdOn = new Date(date);
  const msInDay = 24 * 60 * 60 * 1000;

  createdOn.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  const diff = (+today - +createdOn) / msInDay;
  return diff;
}

export function daysLeft(endDate) {
  if (!endDate) return null;
  const startDate = new Date();
  // The number of milliseconds in all UTC days (no DST)
  const oneDay = 1000 * 60 * 60 * 24;

  // A day in UTC always lasts 24 hours (unlike in other time formats)
  const start = Date.UTC(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate(),
  );
  const end = Date.UTC(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate(),
  );

  // so it's safe to divide by 24 hours
  return Math.min((start - end) / oneDay, 365);
}
