<template>
  <!-- eslint-disable max-len -->
  <div>
    <svg style="position: absolute; top: -9999px">
      <!--
        UI ICONS
        // UI icons are always fills, no lines
      -->
      <symbol id="icon_arrow-download" viewBox="0 0 500 500">
        <path
          d="M130,371a10.91,10.91,0,1,0,0,21.82H370A10.91,10.91,0,1,0,370,371Zm119.81-262a10.91,10.91,0,0,0-10.73,11.06V312l-79.56-79.56a10.91,10.91,0,1,0-15.42,15.44l97.22,97.22h0a10.91,10.91,0,0,0,17.32,0l0-.05,97.19-97.19a10.91,10.91,0,1,0-15.42-15.44L260.91,312V120.14h0a10.92,10.92,0,0,0-11.09-11.06Z" />
      </symbol>

      <symbol id="icon_arrow-down" viewBox="0 0 500 500">
        <path
          d="M399.09,302.07,250,457.08l-149.09-155,31.16-32.41,95.67,99.5V42.92h44.52V369.16l95.67-99.5Z" />
      </symbol>

      <symbol id="icon_arrow-left" viewBox="0 0 500 500">
        <path
          d="M197.93,399.09,42.92,250l155-149.09,32.41,31.16-99.5,95.67H457.08v44.52H130.84l99.5,95.67Z" />
      </symbol>

      <symbol id="icon_arrow-right" viewBox="0 0 500 500">
        <path
          d="M302.07,100.91,457.08,250l-155,149.09-32.41-31.16,99.5-95.67H42.92V227.74H369.16l-99.5-95.67Z" />
      </symbol>

      <symbol id="icon_arrow-up" viewBox="0 0 500 500">
        <path
          d="M100.91,197.93,250,42.92l149.09,155-31.16,32.41-95.67-99.5V457.08H227.74V130.84l-95.67,99.5Z" />
      </symbol>

      <symbol id="icon_close" viewBox="0 0 500 500">
        <path
          d="M291.87,248.68l130,131.74a31.21,31.21,0,0,1,0,43.91,30.22,30.22,0,0,1-43.33,0l-130-131.74-130,131.74a30.22,30.22,0,0,1-43.33,0,31.21,31.21,0,0,1,0-43.91l130-131.74L77.6,119.38a31.21,31.21,0,0,1,0-43.91,30.25,30.25,0,0,1,43.34,0L251,207.21,381,75.47a30.25,30.25,0,0,1,43.34,0,31.21,31.21,0,0,1,0,43.91Z" />
      </symbol>

      <symbol id="icon_info" viewBox="0 0 20 20">
        <path
          d="M13.28 15.72H7.56V14.38C8.48 14.38 8.94 14.14 8.94 13.66V9.48C8.94 8.98 8.48 8.74 7.56 8.74V7.44H11.92V13.64C11.9 14.12 12.36 14.36 13.3 14.36L13.28 15.72ZM8.9 4.18C8.98 3.98 9.1 3.82 9.26 3.66C9.42 3.52 9.58 3.4 9.78 3.32C9.98 3.24 10.18 3.18 10.4 3.2C10.62 3.2 10.84 3.24 11.04 3.32C11.24 3.4 11.42 3.52 11.56 3.66C11.7 3.8 11.82 3.98 11.9 4.18C11.98 4.38 12.04 4.6 12.02 4.82C12.02 5.04 11.98 5.26 11.9 5.46C11.82 5.66 11.7 5.84 11.56 5.98C11.42 6.14 11.24 6.26 11.04 6.34C10.84 6.42 10.62 6.48 10.4 6.46C10.18 6.46 9.96 6.42 9.76 6.34C9.36 6.16 9.06 5.86 8.88 5.46C8.8 5.26 8.74 5.04 8.76 4.82C8.76 4.6 8.8 4.38 8.9 4.18ZM10 0C7.34 0 4.8 1.06 2.92 2.92C1.04 4.78 0 7.34 0 10C0 12.66 1.06 15.2 2.92 17.08C4.78 18.96 7.34 20 10 20C12.66 20 15.2 18.94 17.08 17.08C18.96 15.22 20 12.66 20 10C20 7.34 18.94 4.8 17.08 2.92C15.22 1.04 12.66 0 10 0Z" />
      </symbol>

      <symbol id="icon_more" viewBox="0 0 500 500">
        <path
          d="M314.72,71.22c0,35.59-28.88,64.06-65,64.06s-65-28.47-65-64.06,28.88-64.06,65-64.06S314.72,35.63,314.72,71.22Zm0,180.14c0-33.22-28.88-61.69-65-61.69s-65,28.47-65,61.69c0,35.59,28.88,64.06,65,64.06S314.72,287,314.72,251.36Zm0,177.22c0-35.59-28.87-64.06-65-64.06s-65,28.47-65,64.06,28.88,64.06,65,64.06S314.71,464.17,314.71,428.58Z" />
      </symbol>

      <symbol id="icon_reset" viewBox="0 0 24 24">
        <path
          d="M5.40001 10.7H3.90001C3.20001 10.7 2.90001 11.4 3.10001 11.8L5.80001 16.8C6.10001 17.2 6.70001 17.2 7.10001 16.6L9.80001 11.8C9.90001 11.2 9.70001 10.7 9.00001 10.7H7.60001C8.10001 8 10.4 6.2 13.1 6.2C16.2 6.2 18.7 8.7 18.7 11.8C18.7 14.9 16.5 17.4 13.1 17.4V19.6C17.6 19.6 21 16.1 21 11.7C21 7.3 17.5 4 13.1 4C9.20001 4 5.90001 6.8 5.40001 10.7Z" />
      </symbol>
      <symbol id="icon_graduated" viewBox="0 0 500 500">
        <path
          d="M447.47,153,252.42,97a10.57,10.57,0,0,0-5.24,0L52,153a9.62,9.62,0,0,0-6.88,9.21V188.8a9.53,9.53,0,0,0,6.55,9.1l80.51,26.34c0,.33-.11.54-.11.87V294.9a9.57,9.57,0,0,0,2.94,6.91c26.84,25.68,68.84,41,112.26,41s85.31-15.36,112.25-41a9.3,9.3,0,0,0,2.95-6.91v-69l17.78-5.81V297.2a30.87,30.87,0,0,0-10.58,52.45l-10.26,42a10,10,0,0,0,1.75,8.23,9.68,9.68,0,0,0,7.53,3.73H411a9,9,0,0,0,7.42-3.73,9.27,9.27,0,0,0,1.75-8.23l-10.26-42a30.85,30.85,0,0,0-10.69-52.45V213.92L447.8,198a9.55,9.55,0,0,0,6.54-9.1V162.24A9.62,9.62,0,0,0,447.47,153ZM343.4,290.73c-23.46,20.74-59,33-96.11,33s-72.66-12.29-96.11-33V230.49l95.56,31.38a10.08,10.08,0,0,0,5.89,0l90.66-29.73v58.59Zm55.41,93.59h-18.1l6.65-27.21a17.53,17.53,0,0,0,2.4.11c.76,0,1.64-.11,2.4-.11Zm-9.05-46.19a11.63,11.63,0,1,1,11.56-11.63A11.6,11.6,0,0,1,389.76,338.13Zm45.49-156.25-36,11.85V188a9.62,9.62,0,0,0-7.09-9.22L261.69,143.26a9.59,9.59,0,0,0-4.91,18.54l123.49,33.47V200L249.8,242.78,64.24,181.88v-12.4L249.8,116.16l185.56,53.32v12.4Z" />
      </symbol>
      <symbol id="icon_messages" viewBox="0 0 500 500">
        <path
          d="M182.29,156.65H351.82a9.52,9.52,0,1,0,0-19H182.29a9.52,9.52,0,1,0,0,19Zm0,49.82H351.82a9.52,9.52,0,1,0,0-19H182.29a9.52,9.52,0,1,0,0,19ZM378.75,87.8H155.27a32.6,32.6,0,0,0-32.65,32.56v97.77H101.23a24.68,24.68,0,0,0-24.86,24.54V315.9a24.81,24.81,0,0,0,24.86,24.63h3.94l-5.54,30.88a9.49,9.49,0,0,0,5,10.17,9.38,9.38,0,0,0,4.5,1.12,9.51,9.51,0,0,0,6.75-2.8l40.25-39.37h57.14a24.75,24.75,0,0,0,24.86-24.63v-9.79h32.74l74.5,73.79a9.61,9.61,0,0,0,10.79,1.68,9.47,9.47,0,0,0,5.34-9.42l-6.75-66.05h24a32.69,32.69,0,0,0,32.74-32.56V120.36A32.68,32.68,0,0,0,378.75,87.8ZM334.56,297.43l5.25,50-58.26-57.75a9.89,9.89,0,0,0-6.66-2.71H155.27a13.29,13.29,0,0,1-13.51-13.43V120.36a13.29,13.29,0,0,1,13.51-13.43H378.75a13.29,13.29,0,0,1,13.51,13.43V273.64a13.3,13.3,0,0,1-13.51,13.44H344a9.45,9.45,0,0,0-7,3.08A9.11,9.11,0,0,0,334.56,297.43ZM126,332.7a9.56,9.56,0,0,0-2.07-7.75,9.67,9.67,0,0,0-7.32-3.35H101.23a5.53,5.53,0,0,1-5.72-5.6V242.67a5.53,5.53,0,0,1,5.72-5.6h21.39v36.48a32.69,32.69,0,0,0,32.74,32.56H219V316a5.54,5.54,0,0,1-5.72,5.6h-61a9.53,9.53,0,0,0-6.76,2.7l-21.67,21.27Zm235.39-85.93a9.58,9.58,0,0,0-9.57-9.51H182.29a9.52,9.52,0,1,0,0,19H351.82A9.59,9.59,0,0,0,361.39,246.77Z" />
      </symbol>
      <symbol id="icon_apm" viewBox="0 0 500 500">
        <path
          d="M404.4,407.25H382.83V380.07a132.78,132.78,0,0,0-68.36-116.15,16,16,0,0,1,0-27.86,132.8,132.8,0,0,0,68.3-117.28v-26H404.4a8,8,0,0,0,0-16H95.6a8,8,0,0,0,0,16h21.57v27.18a132.78,132.78,0,0,0,68.36,116.15,16,16,0,0,1-.05,27.86,132.8,132.8,0,0,0-68.3,117.28v26H95.6a8,8,0,0,0,0,16H404.4a8,8,0,0,0,0-16Zm-52.66,0a78.88,78.88,0,0,0-157.75,0H133.17V381.09a116.89,116.89,0,0,1,60.09-103.17,32,32,0,0,0,0-55.82,117,117,0,0,1-60.14-102.17V92.75H366.82v26.16a116.89,116.89,0,0,1-60.09,103.17,32,32,0,0,0,0,55.82,117,117,0,0,1,60.14,102.17v27.18ZM275.3,304.86a10.4,10.4,0,1,1-10.4-10.39A10.4,10.4,0,0,1,275.3,304.86Zm.56-37.39a14.58,14.58,0,1,1-14.58-14.58A14.58,14.58,0,0,1,275.86,267.47Zm23.65-61.29a106.79,106.79,0,0,1-19.34,14,48.77,48.77,0,0,0-14.66,12.68,12.88,12.88,0,0,1-12.31,4.89,62.16,62.16,0,0,1-33.66-17.55c-16.76-16.62-34.33-29.6-40.29-41.11a7.17,7.17,0,0,1,5.66-10.44c5.58-.56,12.44-.58,17.38,1.61,15.82,7,28.51,24.36,40.22,27.12,18.7,4.4,39,3.16,53.43,1.17A4.43,4.43,0,0,1,299.51,206.18Z" />
      </symbol>
      <symbol id="icon_skill" viewBox="0 0 500 500">
        <path
          d="M374.86,372,313.49,268.37A106.34,106.34,0,0,0,250.05,76.75h0a106.34,106.34,0,0,0-63.44,191.62L125.36,371.48h0A8,8,0,0,0,124,376v0a7.85,7.85,0,0,0,8,7.87h38.49l17.46,34.95h0a7.88,7.88,0,0,0,13.77.62L250,340l48.33,79.5h0a7.89,7.89,0,0,0,13.79-.62l17.45-34.94h38.5v0A7.86,7.86,0,0,0,374.86,372ZM159.53,183.08a90.52,90.52,0,1,1,90.52,90.58A90.44,90.44,0,0,1,159.53,183.08ZM324.82,368.16a7.92,7.92,0,0,0-7,4.31l-13.29,26.58-47.71-78.4v0a7.86,7.86,0,0,0-13.52,0l-47.71,78.39-13.29-26.58h0a7.88,7.88,0,0,0-7-4.31H145.6l54.28-91.44a105.67,105.67,0,0,0,100.34,0l54.11,91.44Zm-23.3-228.93C281.92,162.88,262,196.05,247,228a5,5,0,0,1-8.87.35c-10.77-19.18-23.5-40.77-38.48-48.22a2,2,0,0,1-.4-3.35l10.25-8.48a5,5,0,0,1,6.83.42c10.07,10.73,17.26,21.69,24.91,34.95a374.71,374.71,0,0,1,47.63-69.29,5,5,0,0,1,5.93-1.21l6,2.84A2.1,2.1,0,0,1,301.52,139.23Z" />
      </symbol>
      <symbol id="icon_activity" viewBox="0 0 500 500">
        <path
          d="M186,250a64.09,64.09,0,0,0,63.18,64H250a64.16,64.16,0,0,0,64-64,66.12,66.12,0,0,0-2.13-16.55,8.89,8.89,0,0,0-14.64-4.31,18.67,18.67,0,0,1-26.38-26.35,8.89,8.89,0,0,0-4.3-14.67A67.2,67.2,0,0,0,250,186,64.09,64.09,0,0,0,186,250Zm72.72-8.69A36.27,36.27,0,0,0,296.24,250a46.24,46.24,0,1,1-46.77-46.26h.5A36.26,36.26,0,0,0,258.7,241.28Zm190.68,4.12-.38-.55h0c-6.39-8.7-76-99.25-199-99.25-126.49,0-196.45,95.71-199.37,99.79a7.88,7.88,0,0,0,0,9.18,241.25,241.25,0,0,0,42.81,43.16,7.89,7.89,0,0,0,11.18-1.16,7.86,7.86,0,0,0-1.31-11.14A248,248,0,0,1,67.1,250a254.69,254.69,0,0,1,112.78-77.23A104.36,104.36,0,0,0,250,354.39H250a104.36,104.36,0,0,0,70.2-181.58A255.69,255.69,0,0,1,432.91,250a247.24,247.24,0,0,1-36.23,35.43,7.89,7.89,0,1,0,9.85,12.33,241.19,241.19,0,0,0,42.83-43.19A7.92,7.92,0,0,0,449.38,245.4Zm-136.78-58a88.12,88.12,0,0,1,26,62.6,89.19,89.19,0,0,1-25.94,62.64A88,88,0,0,1,250,338.55a88.58,88.58,0,0,1,0-177.16A88.17,88.17,0,0,1,312.6,187.37Z" />
      </symbol>
      <symbol id="icon_stars" viewBox="0 0 500 500">
        <path
          d="M309.27,269.83a13.06,13.06,0,0,0,5.55-12.18l-6.11-52.88,35.37-41.29h0a13.13,13.13,0,0,0,2.41-12.61,13,13,0,0,0-9.53-8.55l-52.46-11.59L261.14,84v0a12.93,12.93,0,0,0-23.16,0L214.59,130.7l-52.45,11.6v0a13,13,0,0,0-7.09,21.16l35.36,41.29-6.1,52.88a13,13,0,0,0,18.92,13l46.34-24,46.35,24A13,13,0,0,0,309.27,269.83Zm-67.39-37.46-39.82,20.72,5.05-44.89h0a16.89,16.89,0,0,0-4-13l-32.64-38L219.3,146.3a17.09,17.09,0,0,0,11.42-8.76l18.86-37.72v0l18.86,37.72,0,0a17,17,0,0,0,11.42,8.76l48.84,10.89-32.64,38a17,17,0,0,0-4,13l5.05,44.89-39.82-20.72A17,17,0,0,0,241.88,232.37Zm214.05,90.52a10.76,10.76,0,0,0-7.89-7.07l-43.41-9.59-19.36-38.72v0a10.71,10.71,0,0,0-19.17,0l-19.36,38.72-43.41,9.59v0a10.76,10.76,0,0,0-5.87,17.51l29.27,34.17-5.05,43.77a10.78,10.78,0,0,0,15.66,10.78l38.36-19.87,38.36,19.87a10.76,10.76,0,0,0,15.63-10.78l-5.05-43.77,29.27-34.17h0A10.85,10.85,0,0,0,455.93,322.89Zm-44,36.13a13.27,13.27,0,0,0-3.11,10.15l3.94,35L381.71,388a13.26,13.26,0,0,0-12,0l-31,16.16,3.93-35h0A13.15,13.15,0,0,0,339.45,359L314,329.4l38.09-8.5a13.3,13.3,0,0,0,8.9-6.83l14.71-29.42v0L390.41,314l0,0a13.34,13.34,0,0,0,8.91,6.83l38.09,8.5Zm-215.26-43.2-43.41-9.59L133.9,267.51v0a10.71,10.71,0,0,0-19.17,0L95.37,306.21,52,315.8v0a10.76,10.76,0,0,0-5.87,17.51L75.36,367.5l-5,43.77A10.77,10.77,0,0,0,86,422.05l38.36-19.87,38.36,19.87a10.77,10.77,0,0,0,15.64-10.78l-5-43.77,29.27-34.17h0a10.78,10.78,0,0,0-5.89-17.51ZM160.55,359a13.27,13.27,0,0,0-3.11,10.15l3.94,35-31-16.16a13.24,13.24,0,0,0-12,0l-31,16.16,3.94-35h0A13.15,13.15,0,0,0,88.07,359L62.62,329.4l38.09-8.5a13.34,13.34,0,0,0,8.91-6.83l14.71-29.42v0L139,314l0,0a13.3,13.3,0,0,0,8.9,6.83l38.1,8.5Z" />
      </symbol>
      <symbol id="icon_show" viewBox="0 0 500 500">
        <path
          d="M253.17,298.33c-28,1.89-50.67-22.27-49.32-51.65,1.36-24.16,19.91-43.59,43-45,28-1.89,50.67,22.27,49.32,51.65C294.79,277,275.79,296.91,253.17,298.33Zm2.26,41.22c-51.58,3.31-94.55-41.22-91.39-95.71,2.72-44.54,37.55-81,80.08-83.86,51.57-3.32,94.55,41.22,91.38,95.71C332.79,300.7,298.41,336.7,255.43,339.55Zm-5.43-217c-77.81,0-148.84,44.54-200.87,117.5a18.52,18.52,0,0,0,0,20.37c52,72.49,123.06,117,200.87,117S398.84,332.91,450.87,260a18.52,18.52,0,0,0,0-20.37C398.84,167.09,327.81,122.55,250,122.55Z" />
      </symbol>
      <symbol id="icon_hide" viewBox="0 0 500 500">
        <path
          d="M78,229.5c-7.49-7.09-15-14.18-22-22.28-3.28-4.05-6.56-7.59-9.37-11.64l-.94-1.52-2.34-2.53a57.45,57.45,0,0,1-3.75-9.62,44,44,0,0,1-.47-8.1c.47-6.07,2.81-12.15,7-16.2a30.92,30.92,0,0,1,6.09-5.06,19.61,19.61,0,0,1,7-2.53,8.47,8.47,0,0,1,3.75-.51h1.87a9.52,9.52,0,0,1,3.75.51,26.4,26.4,0,0,1,11.72,6.07c1.87,2,3.28,4,5.15,6.08a9.14,9.14,0,0,0,2.34,3.54c2.82,3,5.16,6.08,8,9.11a214.39,214.39,0,0,0,37.49,32.4c28.58,20.25,61.38,33.92,95.58,38,5.16.51,10.78,1,16.4,1,7,0,14.06,0,21.09-.51,37-3.54,72.16-17.72,103.09-40a218.29,218.29,0,0,0,38.42-34.94c2.81-3,5.15-6.07,7.49-9.11l.94-1,.94-1,1.41-1.52c.47-.51.93-1,1.4-1.52.94-1,1.88-1.52,2.81-2.53a19.28,19.28,0,0,1,12.18-4.56,30.37,30.37,0,0,1,9.38,1.52c2.34,1,4.68,2,6.56,4.05,2.81,2.53,5.62,5.57,7,9.11a25.1,25.1,0,0,1,2.81,11.65,47.68,47.68,0,0,1-.94,8.1,31.77,31.77,0,0,1-3.28,7.08c-.47,1-1.41,2-1.87,3a25.35,25.35,0,0,1-3.28,4.05c-5.63,7.09-11.72,13.67-17.81,20.25-3.75,3.55-7.5,7.6-11.25,11.14L443.05,268c7,13.17,3.75,29.87-7.5,38.48a26.92,26.92,0,0,1-22,5.06,28.26,28.26,0,0,1-18.27-14.17c-6.09-11.14-12.66-23.29-18.28-33.92-7,4.05-13.59,7.59-20.61,11.14l13.12,52.14c4.21,15.69-3.75,31.9-17.81,37a24.92,24.92,0,0,1-21.55-2,27.75,27.75,0,0,1-13.59-18.22c-4.69-17.22-10.78-38-14.06-50.12-6.09,1.52-12.18,2.53-18.27,3.54-2.35.51-4.69.51-6.56,1v62.78c0,16.71-12.19,29.87-27.65,29.87s-27.65-13.16-27.65-29.87V297.84c-2.34-.5-4.68-.5-6.56-1-6.09-1-12.18-2-18.27-3.54l-14.06,50.12a32.35,32.35,0,0,1-13.59,18.22,26.76,26.76,0,0,1-21.55,2c-14.06-5.06-22-21.27-17.81-37,5.16-18.22,11.25-40.5,14.53-52.14-7-3.55-14.06-7.09-20.62-11.14l-18.27,33.92a26.45,26.45,0,0,1-18.28,14.17,24.91,24.91,0,0,1-22-5.06c-11.24-8.61-15-25.82-7.5-39C63.51,256.33,71.94,241.14,78,229.5Z" />
      </symbol>
      <symbol id="icon_check" viewBox="0 0 500 500">
        <path
          d="M47,265.24,90.75,209l116.92,96.43L399.81,94.76l53.2,47.7L215.13,405.24Z" />
      </symbol>
      <symbol id="icon_cross" viewBox="0 0 500 500">
        <path
          d="M88.85,134.22l45.37-45.37L250.34,204.29,365.78,88.85l45.37,45.37L295.71,250.34,411.15,365.78l-45.37,45.37L250.34,295.71,134.22,411.15,88.85,365.78,204.29,250.34Z" />
      </symbol>
      <symbol id="icon_dash" viewBox="0 0 500 500">
        <path d="M110.1,217.82H389.9v64.36H110.1Z" />
      </symbol>
    </svg>
  </div>
  <!-- eslint-enable max-len -->
</template>

<script>
export default {
  name: 'SvgSymbols',
};
</script>

<style lang="scss" scoped></style>
