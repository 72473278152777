import { Locale } from '@/types/export';
import { GqlMessageCountWhereInput, GqlMessageType } from '@/types/graphql';

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const HAS_LOCALSTORAGE = typeof localStorage !== 'undefined';

export const DEFAULT_LOCALE = Locale.nl;
export const LOCALES = [Locale.nl];

export const LS_ACCESS_TOKEN = 'apollo-token';
export const LS_REFRESH_TOKEN = 'apollo-refresh-token';

export const DEFAULT_MESSAGES_FILTER: GqlMessageCountWhereInput = {
  AND: [
    {
      OR: [
        {
          type: null,
        },
        {
          type: {
            not: {
              equals: GqlMessageType.AUTOMATED,
            },
          },
        },
      ],
    },
  ],
};

export const DEBOUNCE_QUERY_TIME = 350;
