<template>
  <div id="app">
    <SbErrorBoundary
      :stop-propagation="true"
      @errorCaptured="handleBoundaryError">
      <Symbols />
      <SbEnvironmentBorder :environment="environment()" />
      <RouterView />
      <div class="sb_app-version">
        {{ version }}
      </div>
    </SbErrorBoundary>
    <SbGraphQLErrorHandler />
  </div>
</template>

<script>
import SbEnvironmentBorder from '@/components/SbEnvironmentBorder';
import SbErrorBoundary from '@/components/SbErrorBoundary';
import SbGraphQLErrorHandler from '@/components/SbGraphQLErrorHandler';
import Symbols from '@/components/SvgSymbols';
import MutationAuthenticateTeamleader from '@/graphql/mutations/AuthenticateTeamleader.gql';
import { isAdminContext } from './lib/helpers';
import { getRole } from './lib/util-helper';

export default {
  name: 'App',
  components: {
    SbErrorBoundary,
    SbGraphQLErrorHandler,
    SbEnvironmentBorder,
    Symbols,
  },
  data() {
    return {
      activeRow: null,
      version: process.env.VUE_APP_VERSION,
    };
  },
  computed: {
    role: () => getRole(),
    baseUrl() {
      const url = window.location.href;
      return `${url.split('/')[0]}//${url.split('/')[2]}`;
    },
  },
  mounted() {
    console.info('Beheer version', this.version);
    this.checkTeamleaderAuthentication();
    // extends router to have a navigateback function
    this.$router.navigateBack = () => {
      if (window.history?.length > 2) {
        this.$router.go(-1);
      } else {
        this.$router.push('/');
      }
    };
  },
  methods: {
    async checkTeamleaderAuthentication() {
      if (this.role !== 'ADMIN') return;

      function getUrlParameter(name) {
        // eslint-disable-next-line
        const parsedName = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp(`[\\?&]${parsedName}=([^&#]*)`);
        const results = regex.exec(window.location.search);
        return results === null
          ? ''
          : decodeURIComponent(results[1].replace(/\+/g, ' '));
      }

      if (getUrlParameter('error')) {
        this.$Modal.error({
          title: 'Fout',
          content: `Er is iets fout gegaan bij de teamleader authencitation: ${getUrlParameter(
            'error',
          )}`,
        });
        return;
      }

      if (getUrlParameter('code')) {
        console.log('TEAMLEADER CODE', getUrlParameter('code'));
        await this.$apollo.mutate({
          mutation: MutationAuthenticateTeamleader,
          variables: {
            data: {
              code: getUrlParameter('code'),
              redirectUri: this.baseUrl,
            },
          },
        });
        this.$Modal.success({
          title: 'Gelukt',
          content: 'Teamleader is succesvol gekoppeld',
          onOk: () => {
            window.location.href = this.baseUrl;
          },
        });
      }
    },
    environment() {
      const { resourceId, resourceType } = this.$route.params;

      if (this.$route.meta && this.$route.meta.allowUnauthorized) return '';
      if (isAdminContext(this.$router)) return 'default';
      if (this.role === 'COACH') return 'coaches';
      if (resourceType === 'partner') return 'partners';
      if (resourceType === 'school_district') return 'schooldistricts';
      if (resourceId !== 'klassikaal' && resourceId !== 'online')
        return 'schools';

      return this.$route.params.resourceId;
    },
    handleBoundaryError({ err }) {
      console.dir(err);
      // Handle GraphQL errors seperately in graphqlErrorHandler
      if (err.message && err.message.includes('GraphQL error')) return;

      console.error('Boundary error', err);
      this.$Modal.error({
        title: 'Error',
        content: err,
      });
      // return err;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/app.scss';

.sb_app-version {
  position: fixed;
  bottom: 10px;
  left: 14px;
  z-index: 9999;
  font-size: 8px;
  opacity: 0.5;
}
</style>
