<template>
  <div></div>
</template>

<script>
import { addDays } from 'date-fns';
import { onLogout, graphqlErrorHandler } from '@/apollo';
import { date } from '@/lib/filters/date';
import { handleGraphQLErrors } from '@/lib/util-helper';

export default {
  mounted() {
    graphqlErrorHandler(this.handleGraphqlError);
  },
  methods: {
    logout() {
      if (this.$route.name !== 'Login') {
        console.log('logout');
        onLogout();
        this.$router.push({
          name: 'Login',
        });
      }
    },
    showErrorModal(content) {
      // this.boundaryError = message;
      this.$Modal.error({
        title: 'Foutmelding',
        content,
      });
    },
    handleGraphqlError(error) {
      if (error.graphQLErrors) {
        console.dir(error);
        const status =
          error.graphQLErrors[0].extensions &&
          error.graphQLErrors[0].extensions.exception
            ? error.graphQLErrors[0].extensions.exception.status
            : null;
        const { query, code, exception } = error.graphQLErrors[0].extensions;
        const { message } = error.graphQLErrors[0];
        const errorCode =
          exception.response && exception.response.error
            ? exception.response.error
            : code;

        console.log('error message:', message, 'error status', status);

        if (code === 'TEMP_PASSWORD' && status === 206) {
          return this.$Modal.info({
            title: 'Tijdelijk wachtwoord',
            content:
              'Je hebt een tijdelijk wachtwoord. Je wordt naar een pagina geleid om dit naar een wachtwoord naar keuze te veranderen.',
            onOk: () => {
              this.$router.push({
                name: 'NewPassword',
                query: {
                  token: exception.response.token,
                  user_id: exception.response.userId,
                },
              });
            },
          });
        }

        const errorMap = {
          START_DATE_IN_FUTURE:
            'De startdatum van de cursus ligt in de toekomst. Probeer het later nog eens.',
          END_DATE_IN_PAST:
            'De eindatum van je cursus is verstreken. Je kunt niet meer inloggen.',
          NO_LICENSE:
            'Er is op dit moment geen licentie gekoppeld aan je account. Neem contact op met de beheerder.',
          PASSWORD_NOT_SET:
            'Voltooi eerst de stappen in de registratie e-mail die je ontvangen hebt, of vraag een beheerder je wachtwoord in te stellen.',
          INVALID_CREDENTIALS:
            'Je gebruikersnaam en / of wachtwoord kloppen niet',
          SCHOOL_DEACTIVATED: 'De school is niet meer actief',
          RESOURCE_DEACTIVATED:
            'Deze entiteit (school / schooldistrict / partner) is niet meer actief',
        };

        const couponErrorMap = {
          COUPON_MAX_USAGE_EXCEEDED:
            'De coupon is een maximaal aantal keer gebruikt',
          UNKNOWN_COUPON_CODE: 'Ongeldige coupon code',
          COUPON_EXPIRED: 'De coupon is verlopen',
        };

        if (code === 'INVALID_COUPON') {
          return this.showErrorModal(
            couponErrorMap[exception.code]
              ? couponErrorMap[exception.code]
              : message,
          );
        }

        if (errorMap[errorCode]) {
          return this.showErrorModal(errorMap[errorCode]);
        }
        if (errorMap[code]) {
          return this.showErrorModal(errorMap[code]);
        }

        if (status === 400 && message === 'Invalid Refresh Token') {
          return this.logout();
        }

        if (status === 400) {
          if (message === "School can't migrate, licenses would be invalid") {
            return this.showErrorModal(
              'We kunnen de school niet migreren. Er zou een conflict ontstaan met de licenties. Een school met een eigen abonnement, kan niet onder een schooldistrict vallen dat ook een abonnement heeft.',
            );
          }
          if (
            message === "Subscription can't be cancelled within the last month"
          ) {
            return this.showErrorModal(
              `Je kunt het nieuwe abonnement opzeggen vanaf ${date(
                addDays(
                  new Date(exception.response.details.currentRenewalDate),
                  1,
                ),
              )}.`,
            );
          }
          if (message === 'School with BRIN/VAT-number already exists.') {
            return this.showErrorModal(
              'Deze organisatie heeft al een account in ons systeem. Log eerst in met je accountgegevens om licenties bij te bestellen. Heb je vragen over je account, of ben je je accountgegevens kwijt, neem dan contact op via support@brightskills.nl of bel 013-2047022. ',
            );
          }
          console.log('400 error', handleGraphQLErrors(error));
          return this.showErrorModal(handleGraphQLErrors(error));
        }
        if (status === 401) {
          return this.logout();
        }

        if (status === 403) {
          if (message === 'Not allowed to get students') {
            this.$Message.error('Je hebt geen toegang tot deze studenten');
            return false;
          }
        }

        if (status === 404) {
          if (query.includes('{me{')) {
            console.log(
              'User not found. This can happen when you have a valid token, but the user is not in current database',
            );
            return this.logout();
          }
          console.log({
            message,
          });
        }
        return this.showErrorModal(message);
      }

      if (error.networkError) {
        console.dir('NETWORK ERROR', error.networkError);
        if (
          error.networkError.message.includes(
            'Unexpected token < in JSON at position 0',
          )
        ) {
          this.showErrorModal(
            'Het lijkt erop dat er problemen zijn met de server. Probeer het later nog eens',
          );
          return this.$router.push({
            name: 'Login',
          });
        }
        const { status } = error.networkError.statusCode;
        console.log(status);
        if (RegExp('5[0-9][0-9]').test(status)) {
          this.showErrorModal(
            'Het lijkt erop dat er problemen zijn met de server. Probeer het later nog eens',
          );
        }
        this.showErrorModal(
          'Het lijkt erop dat er problemen zijn met de server of het netwerk. Probeer het later nog eens',
        );
      }

      // this.handleBoundaryError({
      //   err: error
      // });
      return false;
    },
  },
};
</script>

<style></style>
