<template>
  <div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'ErrorBoundary',
  props: {
    stopPropagation: {
      type: Boolean,
      default: false,
    },
  },
  errorCaptured(err, vm, info = '') {
    this.$emit('errorCaptured', {
      err,
      vm,
      info,
    });
    if (this.stopPropagation) return false;
    return err;
  },
};
</script>
