import { default as ViewUI, Col, Switch, Cascader, Tag } from 'view-design';
import 'view-design/dist/styles/iview.css';
// @ts-expect-error Not typed
import locale from 'view-design/dist/locale/nl-NL.js';
import Vue from 'vue';
import './theme.less';

Vue.use(ViewUI, {
  locale,
});

// monkey patches Cascader to support case-insenstive search
// @ts-expect-error Not typed
Cascader.computed.querySelections = function querySelections() {
  let selections: any[] = [];
  function getSelections(arr: any, label?: any, value?: any) {
    for (let i = 0; i < arr.length; i += 1) {
      const item = arr[i];
      item.__label = label ? `${label} / ${item.label}` : item.label;
      item.__value = value ? `${value},${item.value}` : item.value;

      if (item.children && item.children.length) {
        getSelections(item.children, item.__label, item.__value);
        delete item.__label;
        delete item.__value;
      } else {
        selections.push({
          label: item.__label,
          value: item.__value,
          display: item.__label,
          item,
          disabled: !!item.disabled,
        });
      }
    }
  }
  getSelections(this.data);
  // adds toLowerCase() to label and query
  selections = selections
    .filter((item) =>
      item.label
        ? item.label.toLowerCase().indexOf(this.query.toLowerCase()) > -1
        : false,
    )
    .map((item) => {
      // item.display = item.display.replace(new RegExp(this.query, 'g'), `<span>${this.query}</span>`);
      return {
        ...item,
        display: item.display.replace(
          new RegExp(this.query, 'g'),
          `<span>${this.query}</span>`,
        ),
      };
    });
  return selections;
};

Vue.component('ICascader', Cascader);
Vue.component('ICol', Col);
Vue.component('ISwitch', Switch);
Vue.component('i-tag', Tag);
