import type { GraphQLErrors } from '@apollo/client/errors';
import { ApolloLink } from 'apollo-link';
import { ErrorResponse, onError } from 'apollo-link-error';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import {
  createApolloClient,
  restartWebsockets,
  // @ts-expect-error Not typed
} from 'vue-cli-plugin-apollo/graphql-client';
import {
  HAS_LOCALSTORAGE,
  LS_ACCESS_TOKEN,
  LS_REFRESH_TOKEN,
} from '@/lib/constants';
import { FILES_ROOT, GRAPHQL_HTTP } from '@/lib/env';
import {
  getId,
  getRefreshToken,
  getAccessToken,
  parseJwt,
  setExpiresIn,
  isTokenExpired,
  setAccessToken,
  setRefreshToken,
} from '@/lib/util-helper';
import { SentryRequestIdApolloLink } from '@/sentry';

Vue.use(VueApollo);

let errorHandler: (error: GraphQLErrors[0] | ErrorResponse) => void | undefined;

export function graphqlErrorHandler(_errorHandler: typeof errorHandler) {
  errorHandler = _errorHandler;
}

// Http endpoint
const httpEndpoint = GRAPHQL_HTTP ?? '';
// Files URL root
export const filesRoot =
  FILES_ROOT || httpEndpoint.substring(0, httpEndpoint.indexOf('/graphql'));

Vue.prototype.$filesRoot = filesRoot;

const link = ApolloLink.from([
  // Add x-request-id to headers
  SentryRequestIdApolloLink,
  new TokenRefreshLink({
    accessTokenField: LS_ACCESS_TOKEN,
    isTokenValidOrUndefined: () =>
      !isTokenExpired() || typeof getAccessToken() !== 'string',
    fetchAccessToken: () =>
      fetch(GRAPHQL_HTTP ?? '', {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          query: `
        mutation {
          refreshToken(data: { userId: "${getId()}", refreshToken: "${getRefreshToken()}" }) {
            accessToken
            refreshToken
          }
        }`,
        }),
        headers: { 'Content-Type': 'application/json' },
      }),
    handleFetch: (accessToken) => {
      setAccessToken(accessToken);
      setExpiresIn(parseJwt(accessToken).exp);
    },
    handleResponse:
      (operation, accessTokenField) => async (response: Response) => {
        const { data } = (await response.json()) as {
          data: { refreshToken: { accessToken: string; refreshToken: string } };
        };
        const { refreshToken, accessToken } = data.refreshToken;

        setRefreshToken(refreshToken);
        return {
          [accessTokenField]: accessToken,
          LS_REFRESH_TOKEN: refreshToken,
        };
      },
    handleError: (error) => {
      console.warn('Your refresh token is invalid. Try to relogin');
      console.error(error);

      // When the browser is offline and an error occurs we don’t want the user to be logged out.
      // We also don’t want to delete a JWT token from the `localStorage` in this case.
      if (
        !navigator.onLine ||
        (error instanceof TypeError &&
          error.message === 'Network request failed')
      ) {
        console.info('Currently offline, so doing nothing');
      } else {
        onLogout();
      }
    },
  }) as unknown as ApolloLink,
  onError((error) => {
    errorHandler?.(error);
  }),
]);

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:9002/graphql',
  // LocalStorage token
  tokenName: LS_ACCESS_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link,

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });
  apolloClient.wsClient = wsClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    clients: {
      apolloClient,
    },
    defaultClient: apolloClient,
  });

  return apolloProvider;
}

export const apollo = createProvider();

// Manually call this when user log in
export async function onLogin(token: string, refreshToken?: string) {
  if (HAS_LOCALSTORAGE) {
    if (token) {
      setAccessToken(token);
      setExpiresIn(parseJwt(token).exp);

      if (refreshToken) setRefreshToken(refreshToken);
    }
  }

  // @ts-expect-error Not typed correctly
  if (apollo?.defaultClient?.wsClient)
    // @ts-expect-error Not typed correctly
    restartWebsockets(apollo.defaultClient.wsClient);

  try {
    await apollo.defaultClient.clearStore();
    // await apolloClient.cache.reset();
  } catch (error) {
    console.error(
      '%cError on cache reset (login)',
      'color: red;',
      (error as { message: string })?.message ?? error,
    );
  }
}

// Manually call this when user log out
export async function onLogout() {
  if (HAS_LOCALSTORAGE) {
    localStorage.removeItem(LS_ACCESS_TOKEN);
    localStorage.removeItem(LS_REFRESH_TOKEN);
    localStorage.removeItem('token-expiration');
  }

  // @ts-expect-error Not typed correctly
  if (apollo?.defaultClient?.wsClient)
    // @ts-expect-error Not typed correctly
    restartWebsockets(apollo.defaultClient.wsClient);

  try {
    await apollo.defaultClient.clearStore();
    // await apolloClient.cache.reset();
  } catch (error) {
    console.error(
      '%cError on cache reset (logout)',
      'color: red;',
      (error as { message: string })?.message ?? error,
    );
  }
}
