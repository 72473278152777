<template>
  <button
    class="sb_button"
    :button-type="buttonType"
    :disabled="disabled"
    :class="{ 'v_icon-only': iconOnly }"
    @click="!disabled && $emit('click')">
    <span class="sb_button_wrapper">
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    /**
     * The type of the button, possible values: 'primary', 'secondary, 'naked', 'naked-body'
     */
    buttonType: {
      default: 'primary',
      validator: (value) =>
        ['primary', 'secondary', 'naked', 'naked-body', 'dropdown'].includes(
          value,
        ),
    },
    /**
     * The disabled state of the button
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconOnly: false,
    };
  },
  mounted() {
    const defaultSlot = this.$slots.default;
    if (defaultSlot.length === 1 && defaultSlot[0].componentOptions) {
      if (defaultSlot[0].componentOptions.tag === 'SvgIcon') {
        this.iconOnly = true;
      }
    }
  },
};
</script>

<style lang="scss">
.sb_button {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0.8rem 1rem;
  margin: 0;
  font-size: 1rem;
  color: $default-text-color;
  text-align: left;
  text-decoration: none;
  letter-spacing: 0;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  background: none;
  background: $brand-white;
  border: none;
  border-radius: 0.3rem;
  outline: none;
  transition: opacity 0.3s ease;
  transition: color 0.2s ease, box-shadow 0.2s ease;

  &_wrapper {
    display: inline-grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-flow: column;
    grid-gap: 15px;
    align-items: center;
    width: 100%;
  }

  .sb_svg-icon {
    transform: scale(1);
  }

  &[button-type='primary'],
  &[button-type='secondary'] {
    color: $brand-white;
  }

  &[button-type='dropdown'] {
    border-bottom: solid 1px $brand-light-gray;
    border-radius: 0;
    transition: background-color 0.3s ease;

    &:hover {
      color: $brand-primary;
      background-color: $brand-lightest-gray;
    }

    &:disabled {
      color: $brand-gray;
    }
  }

  &[button-type='naked'] {
    padding: 0;
    color: $brand-primary-darkest;
    background: none;

    &:hover,
    &:focus {
      color: rgba($brand-primary-darkest, 0.7);
    }

    &:active {
      transform: translateY(1px);
    }

    &:disabled {
      color: rgba($brand-primary-darkest, 0.5);
    }
  }

  &[button-type='naked-body'] {
    min-height: 0;
    padding: 0;
    font-family: Nunito, Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: $brand-primary-darkest;
    letter-spacing: 0;
    background: none;

    .sb_button_wrapper {
      grid-gap: 10px;
    }

    .sb_svg-icon {
      transform: scale(1);
    }

    &:hover,
    &:focus {
      color: rgba($brand-primary-darkest, 0.7);
    }

    &:active {
      transform: translateY(1px);
    }

    &:disabled {
      color: rgba($brand-primary-darkest, 0.5);
    }
  }

  //
  //  ICON ONLY
  //
  &.v_icon-only {
    padding: 18px;
  }

  //
  //  DISABLED
  //

  &:disabled {
    &:hover {
      cursor: not-allowed;

      // pointer-events: none;
    }
  }
}
</style>
