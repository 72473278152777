import VueRouter from 'vue-router';
import { getRole } from './util-helper';

export function isAdminContext(router: VueRouter): boolean {
  return (
    router.currentRoute.params.resourceId === 'default' && getRole() === 'ADMIN'
  );
}

export function context(router: VueRouter): string {
  return router.currentRoute.params.resourceType.toUpperCase();
}
