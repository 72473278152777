export async function getClientInfo() {
  const infoText = await fetch('https://www.cloudflare.com/cdn-cgi/trace').then(
    (res) => res.text(),
  );

  const client = infoText
    .split('\n')
    .map((item) => item.split('='))
    .reduce<Record<string, string>>((output, [key, value]) => {
      if (key) {
        output[key] = value;
      }
      return output;
    }, {});

  return client;
}

export default getClientInfo;
