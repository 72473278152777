import VueCompositionAPI from '@vue/composition-api';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { createI18n, castToVueI18n } from 'vue-i18n-bridge';
import { LOCALES } from '@/lib/constants';
import { Locale } from '@/types/export';

Vue.use(VueCompositionAPI);
Vue.use(VueI18n, { bridge: true });

// Load all locales and remember context
function loadMessages() {
  const context = require.context('./locales', true, /[a-z0-9-_]+\.json$/i);

  const messages = context
    .keys()
    .map((key) => {
      const [locale, namespace] = key
        .replace('./', '')
        .replace('.json', '')
        .split('/');

      return { key, locale, namespace };
    })
    .reduce(
      (
        messages: Record<string, Record<string, string>>,
        { key, locale, namespace },
      ) => {
        return {
          ...messages,
          [locale]: {
            ...messages[locale],
            [namespace]: context(key),
          },
        };
      },
      {},
    );

  return { context, messages };
}

const { context, messages } = loadMessages();

export const i18n = castToVueI18n(
  createI18n(
    {
      locale: Locale.nl,
      fallbackLocale: Locale.nl,
      messages,
      availableLocales: LOCALES,
      fallbackWarn: false,
      missingWarn: false,
    },
    VueI18n,
  ),
);

// Hot updates
if (module.hot) {
  module.hot.accept(context.id, () => {
    const { messages: newMessages } = loadMessages();

    Object.keys(newMessages)
      .filter((locale) => messages[locale] !== newMessages[locale])
      .forEach((locale) => {
        messages[locale] = newMessages[locale];
        i18n.setLocaleMessage(locale, messages[locale]);
      });
  });
}

Vue.use(i18n);
